import styled from 'styled-components';

const StyledButton = styled.button`
	display: flex;
	align-items: center;
	margin-bottom: var(--space-lg);

	span {
		font-size: var(--text-sm);
		color: var(--gray-400);
		text-transform: uppercase;
	}
`;

export default StyledButton;
