import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from '../icon';
import StyledButton from './back.styled';

export default function Back({ label }) {
	return (
		<StyledButton onClick={() => navigate(-1)}>
			<Icon margin="right" color="dark" size="sm" name="IoArrowBackOutline" />
			<span>{label}</span>
		</StyledButton>
	);
}

Back.propTypes = {
	label: PropTypes.string.isRequired,
};
