import { storyblokEditable } from '@storyblok/js';
import PropTypes from 'prop-types';
import React from 'react';

import DynamicComponent from '../components/dynamic-component';
import Back from '../components/elements/back';
import Container from '../components/elements/container';
import Heading from '../components/elements/heading';
import Released from '../components/elements/released';
import Seo from '../components/elements/seo';
import Wrapper from '../components/elements/wrapper';
import Layout from '../components/layout';
import useStoryblok from '../hooks/use-storyblok';

export default function PostTemplate({ pageContext, location }) {
	const story = useStoryblok(pageContext.story, location);
	const blok = story.content;

	return (
		<Layout>
			<Seo {...blok.meta} type="article" path={location.pathname} />
			<Container>
				<Wrapper className="padding-top">
					<Back label="Back to Press Releases" />
					<Heading align="left" color="dark" level={2}>
						{blok.title}
					</Heading>
					<Released date={blok.released} icon={blok.icon} />
				</Wrapper>
			</Container>
			<div {...storyblokEditable(blok)}>
				{blok.body &&
					blok.body.map((child) => (
						<DynamicComponent location={location} blok={child} key={child._uid} />
					))}
			</div>
		</Layout>
	);
}

PostTemplate.propTypes = {
	location: PropTypes.shape({
		pathname: PropTypes.string,
	}).isRequired,
	pageContext: PropTypes.shape({
		story: PropTypes.shape({}),
	}).isRequired,
};
